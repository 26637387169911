<template>
  <v-expansion-panels accordion v-model="panel">
    <v-expansion-panel
      v-model="panel"
      expand
      v-for="(group, i) in this.groups"
      :key="i"
    >
      <v-expansion-panel-header>
        <div class="header">{{ group.name }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <template v-slot:header> </template>
        <v-card flat>
          <v-card-text>
            <v-row no-gutters class="group-background ml-2">
              <v-col lg="12">
                <v-row
                  no-gutters
                  v-for="(section, j) in group.sections"
                  :key="j"
                  class="sections-background ml-5"
                >
                  <v-col lg="12">
                    <v-row
                      class="ml-2 mt-2 mb-2"
                      no-gutters
                      v-for="(field, k) in section.fields"
                      :key="k"
                    >
                      <v-col
                        v-if="field.hidden !== true && field.type == 'message'"
                        lg="12"
                        >{{ field.display }}</v-col
                      >
                      <v-col
                        v-if="field.hidden !== true && field.type != 'message'"
                        lg="4"
                        class="label-text align-center"
                        >{{ field.display }}</v-col
                      >
                      <v-col
                        class="justify-end"
                        v-if="field.hidden !== true && field.type != 'message'"
                        lg="6"
                      >
                        <ColorPicker
                          v-if="field.type == 'color'"
                          :color="field.value"
                        />
                        <v-text-field
                          :color="textFieldColorForField(field)"
                          class="singleline"
                          v-if="field.type == 'singleline'"
                          v-model="values[field.key]"
                          :disabled="
                            field.disabled || section.disabled || group.disabled
                          "
                          light
                          hide-details
                          :placeholder="field.placeholder"
                          :label="labelForSingleField(field)"
                          @change="change"
                        />
                        <v-select
                          color="#93BD20"
                          v-if="field.type == 'select'"
                          :items="field.options"
                          v-model="values[field.key]"
                          light
                          hide-details
                          class="singleline"
                          :disabled="
                            field.disabled || section.disabled || group.disabled
                          "
                          :label="labelForSelectField(field)"
                          @change="change"
                        />
                        <v-radio-group
                          v-if="field.type == 'choice'"
                          v-model="values[field.key]"
                          row
                          :disabled="
                            field.disabled || section.disabled || group.disabled
                          "
                          @change="change"
                        >
                          <v-radio
                            color="#93BD20"
                            v-for="choice in field.choices"
                            :key="choice"
                            :label="choice"
                            :value="choice"
                          ></v-radio>
                        </v-radio-group>
                        <v-radio-group
                          v-if="field.type == 'boolean'"
                          v-model="values[field.key]"
                          row
                          :disabled="
                            field.disabled || section.disabled || group.disabled
                          "
                          @change="change"
                        >
                          <v-radio
                            color="#93BD20"
                            :label="
                              field.labels && field.labels['true']
                                ? field.labels['true']
                                : 'Yes'
                            "
                            :value="true"
                          ></v-radio>
                          <v-radio
                            color="#93BD20"
                            :label="
                              field.labels && field.labels['false']
                                ? field.labels['false']
                                : 'No'
                            "
                            :value="false"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col lg="1" v-if="field.infoText">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" medium>info</v-icon>
                          </template>
                          <div v-html="field.infoText" />
                        </v-tooltip>
                      </v-col>
                      <v-col lg="1" v-if="field.infoText">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" medium>info</v-icon>
                          </template>
                          <div v-html="field.infoText" />
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ColorPicker from "./colorpicker.vue";

export default {
    name: "SettingsForm",
    components: { ColorPicker },
    props: {
        groups: Array,
        values: Object,
        rules: Object,
    },
    data() {
        return {
            panel: -1,
        };
    },
    methods: {
        labelForSelectField(field) {
            if (this.values[field.key] == "") return field.placeholder;
            return "";
        },
        labelForSingleField(field) {
            let error = this.fieldHasError(field);
            return error ? error : "";
        },
        fieldHasError(field) {
            if (!this.values[field.key] || this.values[field.key] == "") return false;
            if (this.rules == undefined || this.rules[field.key] == undefined)
                return false;

            let rules = this.rules[field.key];
            for (const rule of rules) {
                let result = rule(this.values[field.key]);
                if (result !== true) return result;
            }
            return false;
        },
        textFieldColorForField(field) {
            return this.fieldHasError(field) ? "#ff1744" : "#93BD20";
        },
        change() {
            this.$emit("change", this.values);
        },
    },
    mounted() {
        this.panel = this.groups.findIndex(el => el.expanded)
    },
};
</script>

<style scoped>
.input-group input {
  border: 1px solid black;
}
.group-background {
  background-color: #f9f9f9;
}
.sections-background {
  background-color: white;
  margin-bottom: 2px;
  margin-top: 2px;
}
.singleline {
  margin: 5px;
  max-width: 340px;
}

.label-text {
  color: #555555;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
}

.header {
  color: #555555;
  font-family: Roboto;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
}
</style>