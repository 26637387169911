var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panels",
    {
      attrs: { accordion: "" },
      model: {
        value: _vm.panel,
        callback: function ($$v) {
          _vm.panel = $$v
        },
        expression: "panel",
      },
    },
    _vm._l(this.groups, function (group, i) {
      return _c(
        "v-expansion-panel",
        {
          key: i,
          attrs: { expand: "" },
          model: {
            value: _vm.panel,
            callback: function ($$v) {
              _vm.panel = $$v
            },
            expression: "panel",
          },
        },
        [
          _c("v-expansion-panel-header", [
            _c("div", { staticClass: "header" }, [_vm._v(_vm._s(group.name))]),
          ]),
          _c(
            "v-expansion-panel-content",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return undefined
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "group-background ml-2",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { lg: "12" } },
                            _vm._l(group.sections, function (section, j) {
                              return _c(
                                "v-row",
                                {
                                  key: j,
                                  staticClass: "sections-background ml-5",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { lg: "12" } },
                                    _vm._l(section.fields, function (field, k) {
                                      return _c(
                                        "v-row",
                                        {
                                          key: k,
                                          staticClass: "ml-2 mt-2 mb-2",
                                          attrs: { "no-gutters": "" },
                                        },
                                        [
                                          field.hidden !== true &&
                                          field.type == "message"
                                            ? _c(
                                                "v-col",
                                                { attrs: { lg: "12" } },
                                                [_vm._v(_vm._s(field.display))]
                                              )
                                            : _vm._e(),
                                          field.hidden !== true &&
                                          field.type != "message"
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "label-text align-center",
                                                  attrs: { lg: "4" },
                                                },
                                                [_vm._v(_vm._s(field.display))]
                                              )
                                            : _vm._e(),
                                          field.hidden !== true &&
                                          field.type != "message"
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "justify-end",
                                                  attrs: { lg: "6" },
                                                },
                                                [
                                                  field.type == "color"
                                                    ? _c("ColorPicker", {
                                                        attrs: {
                                                          color: field.value,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  field.type == "singleline"
                                                    ? _c("v-text-field", {
                                                        staticClass:
                                                          "singleline",
                                                        attrs: {
                                                          color:
                                                            _vm.textFieldColorForField(
                                                              field
                                                            ),
                                                          disabled:
                                                            field.disabled ||
                                                            section.disabled ||
                                                            group.disabled,
                                                          light: "",
                                                          "hide-details": "",
                                                          placeholder:
                                                            field.placeholder,
                                                          label:
                                                            _vm.labelForSingleField(
                                                              field
                                                            ),
                                                        },
                                                        on: {
                                                          change: _vm.change,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.values[
                                                              field.key
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.values,
                                                              field.key,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "values[field.key]",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  field.type == "select"
                                                    ? _c("v-select", {
                                                        staticClass:
                                                          "singleline",
                                                        attrs: {
                                                          color: "#93BD20",
                                                          items: field.options,
                                                          light: "",
                                                          "hide-details": "",
                                                          disabled:
                                                            field.disabled ||
                                                            section.disabled ||
                                                            group.disabled,
                                                          label:
                                                            _vm.labelForSelectField(
                                                              field
                                                            ),
                                                        },
                                                        on: {
                                                          change: _vm.change,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.values[
                                                              field.key
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.values,
                                                              field.key,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "values[field.key]",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  field.type == "choice"
                                                    ? _c(
                                                        "v-radio-group",
                                                        {
                                                          attrs: {
                                                            row: "",
                                                            disabled:
                                                              field.disabled ||
                                                              section.disabled ||
                                                              group.disabled,
                                                          },
                                                          on: {
                                                            change: _vm.change,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.values[
                                                                field.key
                                                              ],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.values,
                                                                field.key,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "values[field.key]",
                                                          },
                                                        },
                                                        _vm._l(
                                                          field.choices,
                                                          function (choice) {
                                                            return _c(
                                                              "v-radio",
                                                              {
                                                                key: choice,
                                                                attrs: {
                                                                  color:
                                                                    "#93BD20",
                                                                  label: choice,
                                                                  value: choice,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  field.type == "boolean"
                                                    ? _c(
                                                        "v-radio-group",
                                                        {
                                                          attrs: {
                                                            row: "",
                                                            disabled:
                                                              field.disabled ||
                                                              section.disabled ||
                                                              group.disabled,
                                                          },
                                                          on: {
                                                            change: _vm.change,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.values[
                                                                field.key
                                                              ],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.values,
                                                                field.key,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "values[field.key]",
                                                          },
                                                        },
                                                        [
                                                          _c("v-radio", {
                                                            attrs: {
                                                              color: "#93BD20",
                                                              label:
                                                                field.labels &&
                                                                field.labels[
                                                                  "true"
                                                                ]
                                                                  ? field
                                                                      .labels[
                                                                      "true"
                                                                    ]
                                                                  : "Yes",
                                                              value: true,
                                                            },
                                                          }),
                                                          _c("v-radio", {
                                                            attrs: {
                                                              color: "#93BD20",
                                                              label:
                                                                field.labels &&
                                                                field.labels[
                                                                  "false"
                                                                ]
                                                                  ? field
                                                                      .labels[
                                                                      "false"
                                                                    ]
                                                                  : "No",
                                                              value: false,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          field.infoText
                                            ? _c(
                                                "v-col",
                                                { attrs: { lg: "1" } },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        medium:
                                                                          "",
                                                                      },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "info"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c("div", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            field.infoText
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          field.infoText
                                            ? _c(
                                                "v-col",
                                                { attrs: { lg: "1" } },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        medium:
                                                                          "",
                                                                      },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "info"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c("div", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            field.infoText
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }