var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "colorpicker", staticClass: "input-group color-picker" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.colorValue,
            expression: "colorValue",
          },
        ],
        staticClass: "form-control",
        attrs: { type: "text" },
        domProps: { value: _vm.colorValue },
        on: {
          focus: function ($event) {
            return _vm.showPicker()
          },
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.colorValue = $event.target.value
            },
            _vm.updateFromInput,
          ],
        },
      }),
      _c("span", { staticClass: "input-group-addon color-picker-container" }, [
        _c("span", {
          staticClass: "current-color",
          style: "background-color: " + _vm.colorValue,
          on: {
            click: function ($event) {
              return _vm.togglePicker()
            },
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }